html,body, #root {
  height: 100%;
  background-color: rgb(246, 243, 237);
}

.App {
  min-height: 100vh;
  margin: 0 auto;
}

h1, h2, h3, h4, p{
  font-family: 'Varela Round', sans-serif;
}