/* Version de Escritorio */

.Experiencias{
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.titulo-experiencias{
    display:flex;
    align-items: center;
}

.AcercaExperiencias{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
}

.AcercaExperiencias-box{
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    max-width: 400px;
    min-height: 300px;
    margin-bottom: 35px;
    background: linear-gradient(to bottom, #0075cf 0%, #1ececb 100%);
}

.AcercaExperiencias-pasatiempos{
    display: flex;
    margin: 30px;
}

.Descripcion-Empleo{
    display: flex;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}

.Proyectomobil{
    display:none;
}

.titulo-2{
    display:flex;
    justify-content: center;
}

.Recomendacion{
    margin: 10px 200px 50px 200px;
}


/* Version Mobil */

@media (max-width : 1150px){

    .divExperiencias{
        display: none
    }
    .Proyectomobil{
        display: show;
    }
    .Proyectomobil{
        display: flex;
        flex-direction: column;
    }
    .AcercaExperiencias{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 30%;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .AcercaExperiencias-box{
        display: flex;
        border-radius: 20px;
        flex-direction: column;
        min-height: 300px;
        margin-bottom: 35px;
        background: linear-gradient(to bottom, #0075cf 0%, #1ececb 100%);
    }

    .Recomendacion{
        margin: 10px 50px 50px 50px;
    }
}

