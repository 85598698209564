.Acerca {
    display: flex;
    min-height: 50vh;
    max-width: 1500px;
    padding: 2.5%;
    margin: auto;
    justify-content: space-around;
}

p{
    font-size: 19px;
}

.AcercaDescripcion{
    display: flex;
    flex-direction: column;
    max-width: 65%;
}

.calendar{
    border-color: black;
    padding: 25px;
}


.profile-pic{
    width: 300px;
    object-fit: scale-down;
    border-radius: 50%;
    box-shadow: 10px 10px 5px rgb(210, 210, 210);
}

.fotodeperfil{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
}

.simbolo_alerta{
    font-size: 50px;
    margin: 5px;
    color: #02bc7d;
    animation: glow 1s infinite alternate;
}

.botonCV{
    background-color: #be2af2;
    width: 200px;
    height: 50px;
    border-radius: 12px
}

.opentowork{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px
}

.opentowork > p{
    margin: auto;
    font-size: 25px;
    font-weight: bold;
    color: #02bc7d;
}

/*VERSION MOBIL*/
@media (max-width : 1150px){
    .Acerca {
        display: flex;
        min-height: 70vh;
        margin: 20px;
        flex-direction: column;
    }

    .AcercaFoto{
        display: flex;
        min-width: 40%;
        align-items: center;
        min-height: 100%;
        justify-content: center;
        margin-bottom: 25px;
        
    }

    .AcercaDescripcion{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 2%;
        margin-left: 2%;
        margin-bottom: 30px;
        min-width: 90%;
    }

    .calendar{
        display: none;
    }

    p, h3, h2{
        text-align: center;
    }
}

@media (max-width: 600px) {

    .Acerca{
        padding: 5px;
    }

    .profile-pic{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;   
    }

    .calendar{
        display: none;
    }
    
    p, h3, h2{
        text-align: center;
    }
}