/* ESTILOS NAVBAR-NORMAL */
.Contenedor-Navbar{
  display:flex;
}


.Contenedor-Navbar-mobil{
  display: none;
}

/* MENÚ CON OPCIONES PARA NAVEGAR*/
.Navbar-1{
  display: flex;
  grid-auto-flow: column;
  align-items: center;
  justify-content: flex-start;
}

/*ESTILOS DE LO QUE VA DENTRO DE NAVBAR-1*/
.Navegacion{
  display: flex;
  width: 400px;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
} 

/*ESTILO PARA CUANDO LOS BOTONES DE NAV. ESTEN ACTIVADOS O NO LO ESTEN*/
.no-active{
  padding: 5px;
}

.active{
  border-radius: 10px;
  background-color: #e6e6e6;
  padding: 5px;
}

/* REDES */
.NavbarRedes{
  align-items: center;
  display: flex;
  width:400px;
  justify-content: flex-end;
  align-items: center;
  padding: 0 50px 0 0;
}

.NavbarTitulo{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.Navbar-redes{
  display: flex;
}

.Instagram, .Twitter, .LinkedIn{
  display:flex;
  flex-direction: row;
  align-items: center;
  padding: 0.1%;
}

.Instagram:hover{
  color: #ca2154 ;
}
.Twitter:hover{
  color: #1c96e9;
}
.LinkedIn:hover{
  color: #0270ad;
}

.Instagram-mobil, .Twitter-mobil, .LinkedIn-mobil{
  display:flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 0.1%;
}

/*ESTILOS NAVBAR-MOBIL*/

@media (max-width : 1150px){

  .Contenedor-Navbar{
    display: none;
  }
  
  .Contenedor-Navbar-mobil{
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 29px;
  }

  .Menu-Burger{
    display:flex;
    flex-direction: column;
    margin-right: 25px;
  }

  .Menu-abierto{
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .Hamburgueza{
    display: flex;
    align-self: flex-end;
    justify-content: center;
  }

  .Navbar-1-mobil{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* div contendedor de lo que abre la Hamburgueza */
  .Navbar-2-mobil{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* titulo */
  .Navbar-3-mobil{
    display:flex;
    justify-content: center;
    margin-left: 70px;
    width: 100%;
  }

  .Redes{
    display: flex;
    flex-direction: row;
  }
}