.body-proyecto{
    margin-bottom: 50px;
}

.Titulo{
    margin-top: 4%;
    margin-bottom: 15px;
    text-align: center;
}

.TituloSegundo{
    display:flex;
    justify-content: center;
    margin-top: 4%;
}

#Card{
    display:flex;
    margin: 20px;
    width: 350px;
    height: 350px;
}

.CardDescripcion{
    display: flex;
}

#Card:hover {   
    box-shadow: 5px 5px 5px 2px rgb(193, 193, 193);
}

.proyectoTecnologias{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

@media (max-width : 1150px){

    .Proyectos{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: column;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 4%;
        /* border: 3px solid black; */
    }

    .Card{
        display:flex;
        margin: 20px;
        width: 75vw;
        height: auto;
        box-shadow: 10px 10px 5px 2px rgba(255, 255, 255, 0);
    }

}