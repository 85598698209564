.Footer{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0);
    color: white;
    width: 100%;
    padding: 25px;
    box-shadow: 0 -10px 10px gray
}

.Memoji{
    position: relative;
    bottom: 10px;
    width: 150px;
}

@media (min-width : 1920px){
    .Footer{
        padding: 0 300px 0 300px;
    }
}

@media (min-width : 2000px){
    .Footer{
        padding: 0 20% 0 20%;
    }
}
